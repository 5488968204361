/*!
login page 
*/
import React, {useEffect, useState} from "react";
import axios from "axios";

// reactstrap components
import {
    Button, Card, CardHeader, CardBody, CardFooter, CardText, FormGroup, Form, Input, Row, Col
} from "reactstrap";


import Session from "module/session/Session";

import Manager from "module/manager/Manager";
import {API_MANAGER_LOGIN} from "../../const/api.const";
import {ROOT_PATH} from "../../const/path.const";

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const onChangeEmailHandler = (e) => {
        e.preventDefault();
        const emailCurrent = e.target.value;
        setEmail(emailCurrent);
    }

    const onChangePasswordHandler = (e) => {
        e.preventDefault();
        const passwordCurrent = e.target.value;
        setPassword(passwordCurrent);
    }

    const login = async () => {
        return await axios.post(API_MANAGER_LOGIN, {
            email, password
        }, {
            headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8',
            }
        });
    }

    const onSubmitLoginHandler = async () => {
        if (email === "" || password === "")
            alert("이메일과 패스워드를 입력해주세요.");
        else {
            login()
                .then((res) => {
                    if (res.data.code == 200) {
                        Session.setSession(email, res.data.data.managerType, res.data.data.managerId, res.headers.authorization_access);
                        window.location.href = ROOT_PATH;
                    } else {
                        alert('로그인에 실패했습니다. 정보를 다시 확인해주세요.');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert('로그인에 실패했습니다. 정보를 다시 확인해주세요.');
                })
        }

    }

    return (
        <>
            <div className="content login-content w-60 margin-center">
                <Row>
                    <Col md="12" lg="12">
                        <Card>
                            <CardHeader>
                                <h3 className="title text-center">Login</h3>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col className="pl-md-4 margin-center" md="9">
                                            <FormGroup>
                                                <label htmlFor="InputEmail">
                                                    이메일
                                                </label>
                                                <Input placeholder="example@email.com" type="email" id="email"
                                                       className="fs-5" onChange={onChangeEmailHandler}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pl-md-4 margin-center" md="9">
                                            <FormGroup>
                                                <label htmlFor="InputPassword">
                                                    비밀번호
                                                </label>
                                                <Input placeholder="비밀번호를 입력하세요" type="password" id="password"
                                                       className="fs-5" onChange={onChangePasswordHandler}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                            <CardFooter className="text-center" style={{backgroundColor: "none"}}>
                                <Button className="btn-fill" color="primary" type="submit"
                                        onClick={onSubmitLoginHandler}>
                                    로그인
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Login;
