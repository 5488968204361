import axios from "axios";

// Event class
// ** 할당 해제 / ** try,catch 하는거 신경쓰기 
class Event {

  constructor(baseUrl, headers) {
    this.uploadUrl = process.env.REACT_APP_BASICAPI+'upload/'+baseUrl; // api 전달 url
    this.baseUrl = process.env.REACT_APP_BASICAPI+baseUrl; // api 전달 url
    this.headers = headers; //api 전달 headers
  }

    async create(data) {
      const url = `${this.baseUrl}`;
      const method = "POST";

      return await this.sendAxios(url, method, data); //make option
    }


    async uploadFile(id, data, headers) {
        //upload media
        const url = `${this.uploadUrl}/`+id; 
        const method = "POST";

        return await this.sendAxios(url, method, data, '',headers); //make option
    }

    async update(data) {
        const url = `${this.baseUrl}`;
        const method = "PUT";

        return await this.sendAxios(url, method, data); //make option
    }

    async remove(data) {
        const url  = `${this.baseUrl}` + '/' + data; // api 전달 url
        const method = 'DELETE'; // api 전달 method

        return await this.sendAxios(url, method); //make option
    }

    async findOne(data){
        //특정 리스트 가져오기
        const url  = `${this.baseUrl}?`+data; // api 전달 url
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method);//make option
    }

    async findAll(params){

        //전체리스트 가져오기
        const url = `${this.baseUrl}/all`+params;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method);//make option
    }

    async sendAxios(url, method, data , params , headers = null) {
    
        const option = {
          url: url,
          method: method,
          headers: headers ?? this.headers,
          data: data,
          params : params
        };
  
        console.log(option);
      
        const response = axios(option);
      
      return response;
    
    }

}

export default Event;