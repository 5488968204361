import axios from "axios";
import Event from "module/event/Event";

// Manager class  
class Manager extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }

    async login(data){
        const url = process.env.REACT_APP_LOGINAPI;
        const method = 'POST';
        return await this.sendAxios(url, method, data); //make option
    }

}

export default Manager;